import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { Equipment, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  equipments: { items: Equipment[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentsGQL extends Query<Response, GraphQLParams<'equipment'>> {
  document = gql(config.equipment.query);
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentTreeGQL extends Query<
  {
    equipmentTrees: { items: Equipment[]; totalCount: number };
  },
  GraphQLParams<'equipment'>
> {
  document = gql(config.equipmentTree.query);
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentsCountGQL extends Query<
  Response & { equipmentsCount: number },
  GraphQLParams<'equipment'>
> {
  document = gql(config.equipment.query);
}
