import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Part } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  parts: { items: Part[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class PartsGQL extends Query<Response, GraphQLParams<'part'>> {
  document = gql(config.part.query);
}
