import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { Consumable, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  consumables: { items: Consumable[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class ConsumablesGQL extends Query<Response, GraphQLParams<'consumable'>> {
  document = gql(config.consumable.query);
}