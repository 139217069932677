/********************************************************************
// Limitations
// -----------
//  https://github.com/zxing-js/ngx-scanner/wiki/Limitations
//
// - The component relies on zxing-typescript which currently supports
//   some 2D and 1D barcode formats, but not them all.
//
// - On iOS devices camera access works only in native Safari. This is limited WebRTC support by Apple.
//************************************************************************/

import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent, ZXingScannerModule } from '@zxing/ngx-scanner';
@Component({
  standalone: true,
  imports: [ZXingScannerModule],
  selector: 'app-barcode-reader',
  templateUrl: './barcode-reader.component.html',
  styleUrls: ['./barcode-reader.component.css'],
})
export class BarcodeReaderComponent {
  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent | undefined;

  // Allowed format seems to contain too many formats and makes it not working. Overriding in the template.
  // All UPC have detection issues. So disabling it.
  allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODE_39,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_93,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.MAXICODE,
  ];

  constructor(public dialogRef: MatDialogRef<BarcodeReaderComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  scanSuccessHandler($event: any): void {
    this.dialogRef.close($event);
  }

  scanCompleteHandler($event: any): void {
    console.log('scanCompleteHandler', $event);
  }
}
