import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { BarcodeReaderComponent } from './barcode-reader.component';

@Injectable({
  providedIn: 'root',
})
export class BarcodeReaderService {
  constructor(public dialog: MatDialog) {}

  launchBarcodeReader(): Observable<string> {
    const dialogRef = this.dialog.open(BarcodeReaderComponent, {
      panelClass: ['Zoom-out', 'animated', 'dynamic-look-up'],
      data: '',
      position: { top: '100px' },
      width: '60vw',
      height: '80vh',
    });

    return dialogRef.afterClosed();
  }
}
