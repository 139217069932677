import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Item } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  items: { items: Item[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class ItemsGQL extends Query<Response, GraphQLParams<'item'>> {
  document = gql(config.item.query);
}
